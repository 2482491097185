define("discourse/plugins/discourse-group-membership-ip-block/discourse/templates/connectors/group-edit/field-container", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group ip-blocks-list">
    <label class="control-label" for="ip_blocks_list">
      {{i18n "admin.groups.ip_blocks_list.label"}}
    </label>
    <ValueList
      @values={{group.custom_fields.ip_blocks_list}}
      @name="ip_blocks_list"
      class="input-xxlarge"
    />
    <div class="desc">{{i18n "admin.groups.ip_blocks_list.description"}}</div>
  </div>
  */
  {
    "id": "T1OFHo7v",
    "block": "[[[10,0],[14,0,\"control-group ip-blocks-list\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"ip_blocks_list\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"admin.groups.ip_blocks_list.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],[[24,0,\"input-xxlarge\"]],[[\"@values\",\"@name\"],[[30,0,[\"group\",\"custom_fields\",\"ip_blocks_list\"]],\"ip_blocks_list\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"desc\"],[12],[1,[28,[35,2],[\"admin.groups.ip_blocks_list.description\"],null]],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `group` property path was used in the `discourse/plugins/discourse-group-membership-ip-block/discourse/templates/connectors/group-edit/field-container.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.group}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"div\",\"label\",\"i18n\",\"value-list\"]]",
    "moduleName": "discourse/plugins/discourse-group-membership-ip-block/discourse/templates/connectors/group-edit/field-container.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});